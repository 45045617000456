


























































import { defineComponent, inject, PropType } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { commentIsFlagged, commentIsLiked } from '@/composables/useForum';

export default defineComponent({
  name: 'Comment',
  props: {
    questionId: {
      required: true,
      type: [Object, String]
    },
    comment: {
      required: true,
      type: Object as PropType<Comment>
    },
    studentAdkData: {
      required: true,
      type: Object
    }
  },
  setup() {
    const readonly = inject('readonly', false);
    return { commentIsFlagged, commentIsLiked, readonly };
  }
});
